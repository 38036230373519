import { useRouter } from 'next/router';
import { useEffect } from 'react';

const tmParams = [
  'utm_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_source_platform',
  'utm_campaign_id',
  'utm_creative_format',
  'utm_marketing_tactic',
  'mtm_source',
  'mtm_medium',
  'mtm_campaign',
  'mtm_content',
  'mtm_kwd',
  'mtm_cid',
  'mtm_group',
  'mtm_placement'
];

export default function useUTMParams() {
  const router = useRouter();

  useEffect(() => {
    const queryParams = router.query;

    // Save UTM params in session storage
    tmParams.forEach((param) => {
      if (queryParams[param]) {
        sessionStorage.setItem(param, queryParams[param]);
      }
    });

    // Check if UTM parameters exist in session storage and reapply them
    const url = new URL(window.location.href);
    let hasAddedParams = false;

    tmParams.forEach((param) => {
      if (!queryParams[param] && sessionStorage.getItem(param)) {
        url.searchParams.set(param, sessionStorage.getItem(param));
        hasAddedParams = true;
      }
    });

    if (hasAddedParams) {
      router.replace(url.toString(), undefined, { shallow: true });
    }
  }, [router]);
}
