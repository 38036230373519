import { Bank, GearSix, Money, Question } from 'phosphor-react';
import React, { useMemo } from 'react';
import { PAGE_GROUPS, ROUTES } from './constants';
import usePageGroup from './usePageGroup';

const useSidebarMenus = () => {
  const { getRandomPath } = usePageGroup();

  const menus = useMemo(
    () => [
      {
        id: 1,
        title: 'Account Settings',
        icon: <GearSix size={24} />,
        href: ROUTES.ACCOUNT_SETTING
      },
      {
        id: 2,
        title: 'Manage Plan',
        icon: <Money size={24} />,
        href: getRandomPath(PAGE_GROUPS.SUBSCRIPTION) ?? ROUTES.MANAGE_PLAN
      },
      {
        id: 3,
        title: 'Billing History',
        icon: <Bank size={24} />,
        href: ROUTES.BILLING_HISTORY
      },
      {
        id: 4,
        title: 'FAQ’s',
        icon: <Question size={24} />,
        href: ROUTES.ACCOUNT_FAQ
      }
    ],
    [getRandomPath]
  );

  return menus;
};

export default useSidebarMenus;
