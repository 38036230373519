var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_APP_SENTRY_DSN;
const APP_ENV = process.env.NEXT_APP_ENV || 'dev';

if (APP_ENV !== 'local') {
  Sentry.init({
    tracesSampleRate: 1.0,
    dsn: SENTRY_DSN,
    environment: APP_ENV
  });
}
