import { ApolloLink, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { notification } from 'antd';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import router from 'next/router';
import { CheckCircle, Info, Warning, XCircle } from 'phosphor-react';
import AppInfo from '../../../package.json';
import { ROUTES } from '../../common/constants';

let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.NEXT_APP_SERVER_GRAPH_URL
});

const httpAuthServerLink = createHttpLink({
  uri: process.env.NEXT_APP_AUTH_GRAPH_URL
});

const httpAdminServerLink = createHttpLink({
  uri: process.env.NEXT_APP_SERVER_ADMIN_GRAPH_URL
});

export const toast = ({ message: content, type }) => {
  if (typeof window === 'undefined') {
    return;
  }
  notification?.destroy();
  switch (type) {
    case 'info':
      notification?.info({
        message: content,
        icon: <Info size={24} />,
        placement: 'bottomRight',
        closeIcon: '',
        style: {
          width: 381
        }
      });
      break;
    case 'success':
      notification?.success({
        message: content,
        icon: <CheckCircle size={24} />,
        placement: 'bottomRight',
        closeIcon: '',
        style: {
          width: 381
        }
      });
      break;
    case 'warning':
      notification?.warning({
        message: content,
        icon: <Warning size={24} />,
        placement: 'bottomRight',
        closeIcon: '',
        style: {
          width: 381
        }
      });
      break;
    case 'error':
      notification?.error({
        message: content,
        icon: <XCircle size={24} />,
        placement: 'bottomRight',
        closeIcon: '',
        style: {
          width: 381
        }
      });
      break;
    default:
      break;
  }
};

const authLink = setContext((_, { headers }) => {
  const { token, workspaceId, appId, ...rest } = headers || {};

  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'x-workspace-id': workspaceId ?? process.env.NEXT_APP_WORKSPACE_UUID,
      'x-app-id': appId ?? process.env.NEXT_APP_APPUUID,
      ...rest
    }
  };
});

const responseMessageLink = new ApolloLink((operation, forward) =>
  forward(operation)?.map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object?.keys(data)?.length > 0 &&
      data?.[`${Object?.keys(data)?.[0]}`]?.message
    ) {
      if (
        [
          'upsertVideoWatchTime',
          'upsertPodcastPlayTime',
          'createDonation',
          'createSearchHistory',
          'createSubscription'
        ].includes(Object?.keys(data)?.[0])
      )
        return response;

      setTimeout(() => {
        const oResponse = data?.[`${Object?.keys(data)?.[0]}`];

        if (!response) {
          return;
        }

        toast({
          message: oResponse?.message || 'Operation successful',
          type: oResponse?.status === 'ERROR' ? 'error' : 'success'
        });
      }, 1000);
    }
    return response;
  })
);

const errorLink = onError((options) => {
  const { graphQLErrors, networkError, response } = options;

  if (networkError?.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError?.result?.message) {
        toast({
          message: networkError?.result?.message,
          type: 'error'
        });
      }
    }, 200);
    if (typeof window !== 'undefined') router?.replace(ROUTES?.HOME);
    return;
  }

  if (graphQLErrors?.length > 0) {
    const isForBidden =
      get(graphQLErrors?.[0], 'extensions.code') === 'FORBIDDEN';

    if (!isForBidden) {
      setTimeout(() => {
        toast({
          message: graphQLErrors?.[0]?.message,
          type: 'error'
        });
      }, 1000);
    } else {
      setTimeout(() => {
        toast({
          message: 'Something went wrong!',
          type: 'error'
        });
      }, 1000);
    }
  }
  const isNotFoundPage =
    get(graphQLErrors?.[0], 'extensions.code') === 'PAGE_NOT_FOUND';

  if (response) {
    response?.errors?.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;
      if (!isNotFoundPage) {
        // Enable when sentry integrated
        Sentry?.captureException(
          new Error(
            `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (typeof window !== 'undefined') {
        if (extensions?.code === 'SESSION_EXPIRED') {
          window.location.reload();
        }
        if (extensions?.code === 'FORBIDDEN') {
          router?.replace(ROUTES?.FORBIDDEN);
        }
        if (extensions?.code === 'INVALID_TOKEN') {
          router?.replace(ROUTES?.LOGOUT);
        }
        if (
          extensions?.code === 'UNAUTHENTICATED' ||
          extensions?.code === 405 ||
          extensions?.exception?.name === 'JsonWebTokenError'
        ) {
          router?.replace(ROUTES?.HOME);
        }
        if (extensions?.code === 'SERVICE_UNAVAILABLE') {
          router?.replace(ROUTES?.MAINTENANCE_PAGE);
        }
      }

      // eslint-disable-next-line no-console
      return console?.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console?.log(`[Network error]: ${networkError}`);
    Sentry?.captureException(new Error(`[Network error]: ${networkError}`));
  }
});

const client = new ApolloClient({
  cache: cacheData,
  ssrMode: typeof window === 'undefined',
  link: from([responseMessageLink, errorLink, authLink, httpLink]),
  name: 'web',
  version: AppInfo?.version
});

const adminClient = new ApolloClient({
  cache: cacheData,
  ssrMode: typeof window === 'undefined',
  link: from([authLink, httpAdminServerLink]),
  name: 'web',
  version: AppInfo?.version
});

const authClient = new ApolloClient({
  cache: cacheData,
  ssrMode: typeof window === 'undefined',
  link: from([responseMessageLink, errorLink, authLink, httpAuthServerLink]),
  name: 'web',
  version: AppInfo?.version
});

export default client;

export { adminClient, authClient };
