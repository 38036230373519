import sample from 'lodash/sample';
import { useCallback } from 'react';
import { useApp } from '../providers/AppContext';
import { PAGE_SLUG } from './constants';

const usePageGroup = () => {
  const {
    state: { pageGroups }
  } = useApp();

  const getPageGroup = useCallback(
    (groupKey) => pageGroups?.[groupKey] ?? null,
    [pageGroups]
  );

  const getRandomPath = useCallback(
    (groupKey) => {
      const group = getPageGroup(groupKey);
      if (!group) return null;
      const randomPage = sample(group);
      if (!randomPage) return null;
      return `${PAGE_SLUG[randomPage?.type]}/${randomPage?.slug}`;
    },
    [getPageGroup]
  );

  return { data: pageGroups, getRandomPath, getPageGroup };
};

export default usePageGroup;
