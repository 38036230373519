import { Menu } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import CustomLink from '../CustomLink';
import { BREAKPOINTS } from './constants';

function Menubar({ menuItems, setIsOpen, onClose }) {
  const router = useRouter();
  const { pathname } = router;
  const [isDesktop, setDesktop] = useState(null);

  const items = menuItems
    ?.filter((menu) => menu?.position === 'HEADER')
    ?.map((menu) => ({
      label: (
        <CustomLink
          name={menu?.name}
          url={menu?.url}
          type={menu?.type}
          internalPageType={menu?.internalPageType}
          target={menu?.openInSameTab ? '_self' : '_blank'}
          icon={
            menu?.icon && (
              <Image src={menu?.icon} width={20} height={20} alt={menu?.name} />
            )
          }
        />
      ),
      key: menu?.url
    }));

  useEffect(() => {
    setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <Menu
      onClick={() => {
        setIsOpen(false);
        onClose();
      }}
      selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
      // mode={isDesktop ? 'horizontal' : 'vertical'}
      mode="vertical"
      items={items}
    />
  );
}
export default Menubar;
