import React from 'react';
import AppInfo from '../../package.json';

// TODO: comment menuItems for future use
function Footer({
  // menuItems,
  appName
}) {
  // const footerItems = menuItems
  //   ?.filter((menu) => menu?.position === 'FOOTER')
  //   ?.map((menu) => ({
  //     id: menu?.id,
  //     title: menu?.name,
  //     href: menu?.url,
  //     internalPageType: menu?.internalPageType,
  //     type: menu?.type,
  //     target: menu?.openInSameTab ? '_self' : '_blank'
  //   }));
  return (
    <footer>
      <div className="container">
        <div className="footer-bottom ">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 2.94531C4.96243 2.94531 2.5 5.40775 2.5 8.44531C2.5 11.4829 4.96243 13.9453 8 13.9453C11.0376 13.9453 13.5 11.4829 13.5 8.44531C13.5 5.40775 11.0376 2.94531 8 2.94531ZM1.5 8.44531C1.5 4.85546 4.41015 1.94531 8 1.94531C11.5899 1.94531 14.5 4.85546 14.5 8.44531C14.5 12.0352 11.5899 14.9453 8 14.9453C4.41015 14.9453 1.5 12.0352 1.5 8.44531Z"
              fill="#FAF4E8"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.59633 6.53629C8.19566 6.41113 7.76577 6.4152 7.36754 6.54795C6.96932 6.68069 6.62296 6.93536 6.37752 7.27589C6.13207 7.61642 6 8.02555 6 8.44531C6 8.86508 6.13207 9.2742 6.37752 9.61473C6.62296 9.95526 6.96932 10.2099 7.36754 10.3427C7.76577 10.4754 8.19566 10.4795 8.59633 10.3543C8.99701 10.2292 9.34814 9.98112 9.6 9.64531C9.76569 9.4244 10.0791 9.37963 10.3 9.54531C10.5209 9.711 10.5657 10.0244 10.4 10.2453C10.0222 10.749 9.49551 11.1211 8.8945 11.3089C8.29349 11.4966 7.64865 11.4905 7.05132 11.2914C6.45398 11.0922 5.93444 10.7102 5.56627 10.1994C5.19811 9.68865 5 9.07496 5 8.44531C5 7.81566 5.19811 7.20198 5.56627 6.69118C5.93443 6.18039 6.45398 5.79837 7.05132 5.59926C7.64865 5.40015 8.2935 5.39403 8.8945 5.58177C9.49551 5.76951 10.0222 6.14159 10.4 6.64531C10.5657 6.86623 10.5209 7.17963 10.3 7.34531C10.0791 7.511 9.76569 7.46623 9.6 7.24531C9.34814 6.9095 8.99701 6.66145 8.59633 6.53629Z"
              fill="#FAF4E8"
            />
          </svg>
          {appName}. All Rights Reserved{' '}
          {process.env.NEXT_APP_SHOW_VERSION === 'true'
            ? `(v${AppInfo?.version})`
            : ''}
        </div>
      </div>
    </footer>
  );
}
export default Footer;
