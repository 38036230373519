import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import axios from 'axios';
import { SessionProvider } from 'next-auth/react';
import App from 'next/app';
import Head from 'next/head';
import AppInfo from '../../package.json';
import { CACHE_REVALIDATION_TIME, STATIC_DATA_KEYS } from '../common/constants';
import useUTMParams from '../common/useUTMParams';
import {
  injectColorsStyleString,
  injectFontsStyleString
} from '../common/utils';
import Layout from '../components/Layout';
import client from '../lib/apollo';
import Matomo from '../lib/matomo';
import { AppContextProvider } from '../providers/AppContext';
import Themejson from '../styles/style.json';
import '../styles/style.less';

function MyApp({ Component, pageProps, menus, config, pageGroups }) {
  useUTMParams();
  // eslint-disable-next-line no-console
  console.log('APP_VERSION ', AppInfo?.version);
  const appName = config?.APP_NAME?.value ?? 'Breitbart';
  return (
    <>
      <Head>
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: injectColorsStyleString(config)
          }}
        />
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: injectFontsStyleString(config)
          }}
        />
        <link rel="icon" href={config?.APP_FAVICON?.value ?? '/favicon.ico'} />
        <title>{appName}</title>
        <meta property="og:title" content={appName} key="og-title" />
      </Head>

      <Matomo>
        <SessionProvider
          session={pageProps?.session}
          refetchOnWindowFocus={false}
          refetchInterval={CACHE_REVALIDATION_TIME}
          basePath="/api/auth"
          baseUrl={process.env.NEXTAUTH_URL}
        >
          <ApolloProvider client={client}>
            <AppContextProvider config={config} pageGroups={pageGroups}>
              <ConfigProvider theme={Themejson}>
                {/* for noe it's commented as this popup is not required, It will be removed later. */}
                {/* <CookiePopup /> */}

                <Layout
                  menus={menus || []}
                  headerLogo={config?.APP_IMAGE}
                  headerLogoPosition={config?.APP_IMAGE_POSITION}
                  headerLogoSize={config?.APP_IMAGE_SIZE}
                  appName={appName}
                  showMenu={config?.SHOW_MENU}
                >
                  <Component config={config} {...pageProps} />
                </Layout>
              </ConfigProvider>
            </AppContextProvider>
          </ApolloProvider>
        </SessionProvider>
      </Matomo>
    </>
  );
}

MyApp.getInitialProps = async (context) => {
  const pageProps = await App.getInitialProps(context);
  const { appId, workspaceId } = context?.ctx?.query || {};

  const baseURL = `${process.env.NEXT_APP_STATIC_DATA_URL}/static/${
    workspaceId ?? process.env.NEXT_APP_WORKSPACE_ID
  }/${appId ?? process.env.NEXT_APP_APPID}`;

  const configPromise = axios.get(
    `${baseURL}/${STATIC_DATA_KEYS.CONFIGS}.json`
  );
  const pageGroupsPromise = axios.get(
    `${baseURL}/${STATIC_DATA_KEYS.PAGE_GROUPS}.json`
  );

  try {
    const [configResponse, pageGroupsResponse] = await Promise.allSettled([
      configPromise,
      pageGroupsPromise
    ]);

    const showMenus = configResponse.value?.data?.SHOW_MENU?.value === 'YES';

    let menus = [];

    if (showMenus) {
      const menusResponse = await axios.get(
        `${baseURL}/${STATIC_DATA_KEYS.MENU}/en.json`
      );

      if (menusResponse.data?.length >= 0) {
        menus = menusResponse.data;
      }
    }

    return {
      ...pageProps,
      config:
        configResponse.status === 'fulfilled'
          ? configResponse.value.data
          : null,
      pageGroups:
        pageGroupsResponse.status === 'fulfilled'
          ? pageGroupsResponse.value.data
          : null,
      menus
    };
  } catch (error) {
    return {
      ...pageProps,
      config: null,
      pageGroups: [],
      menus: []
    };
  }
};

export default MyApp;
