import { Layout as LayoutAnt } from 'antd';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../common/constants';
import Footer from './Footer';
import Header from './Header';
import RouteProgress from './RouteProgress';

function Layout({
  children,
  headerLogo,
  appName,
  headerLogoPosition,
  headerLogoSize,
  menus = [],
  showMenu
}) {
  const router = useRouter();
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState(true);
  const [oldRouteVal, setOldRouteVal] = useState(router.asPath);

  const loginClass = [
    ROUTES.LOGIN,
    ROUTES.SIGN_UP,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.VERIFY_PHONE_NUMBER,
    ROUTES.VERIFY,
    ROUTES.VERIFY_YOUR_EMAIL,
    ROUTES.VERIFICATION,
    ROUTES.ACCOUNT_SETTING,
    ROUTES.BILLING_HISTORY,
    ROUTES.RESET_PASSWORD,
    ROUTES.MANAGE_PLAN
  ].some((path) => pathname.startsWith(path) && pathname !== ROUTES.MANAGE_PLAN)
    ? 'login-page'
    : '';

  useEffect(() => {
    if (router.isReady) {
      setIsLoading(false);
    }
    const handleStart = (routeData) => {
      if (routeData !== oldRouteVal) setIsLoading(true);
    };

    const handleComplete = () => {
      setIsLoading(false);
      setOldRouteVal(() => router.asPath);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router, oldRouteVal]);

  return (
    <LayoutAnt className={`layout ${loginClass}`}>
      <Header
        menuItems={menus}
        appName={appName}
        headerLogo={headerLogo}
        headerLogoPosition={headerLogoPosition}
        headerLogoSize={headerLogoSize}
        showMenu={showMenu}
      />
      <div className="wrapper">
        {isLoading && <RouteProgress />}
        {children}
      </div>
      <Footer appName={appName} />
    </LayoutAnt>
  );
}
export default Layout;
