import router from 'next/router';

function CustomAnchor({ href, children, className }) {
  const linkHref = href;

  function customLinkOnClick(e) {
    e.preventDefault();
    router.push(linkHref);
  }

  return (
    <a href={linkHref} onClick={customLinkOnClick} className={className}>
      {children}
    </a>
  );
}

export default CustomAnchor;
