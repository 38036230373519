import Placeholder from '../../public/images/imagePlaceholder.png';

export const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  TV: 'tv'
};

export const BREAKPOINTS = {
  [DEVICES.MOBILE]: 767,
  [DEVICES.TABLET]: 1024
};

export const STATIC_DATA_KEYS = {
  MENU: 'menus',
  UI_LABELS: 'ui-labels',
  CONFIGS: 'config',
  MANIFEST: 'manifest',
  PAGE_GROUPS: 'page-group'
};

export const DEFAULT_META_TITLE = 'Breitbart';
export const MEMBERS_ONLY = 'MEMBERS ONLY';
export const DEFAULT_META_DESCRIPTION = 'Breitbart platform';

export const APP_NAME = 'Breitbart';

export const ROLES = {};

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGN_UP: '/signup',
  EMAIL: '/email',
  JOIN: '/join',
  VERIFICATION: '/verification',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  MOVIE_PAGE: '/movie',
  THANK_YOU_PAGE: '/thank-you',
  FAQ: '/FAQ',
  START_TABLE: '/conversations/start-conversation',
  MY_TABLE: '/conversations/my-conversations',
  INVITE_TABLE: '/conversations/invite',
  EDIT_TABLE: '/conversations/edit',
  TABLE: '/conversations',
  ACCOUNT_PROFILE: '/profile',
  ACCOUNT_SETTING: '/settings',
  VIDEOS: '/videos',
  COLLECTIONS: '/collections',
  TAGS: '/tags',
  TOPICS: '/topics',
  ACCOUNT_FAQ: '/faqs',
  SEARCH: '/search',
  TERMS_AND_CONDITION: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  CONTACT: '/contact',
  FORBIDDEN: '/403',
  MAINTENANCE: '/maintenance.html',
  PLAYLISTS: '/playlists',
  INTERNAL_SERVER_ERROR: '/500',
  PODCASTS: '/podcasts',
  VERIFY_YOUR_EMAIL: '/verify-your-email',
  VERIFY_PHONE_NUMBER: '/verify-phone-number',
  MANAGE_PLAN: '/manage-plan',
  MANAGE_MOBILE_PLAN: '/manage-mobile-plan',
  FORMS: '/forms',
  BILLING_HISTORY: '/billing-history',
  CALLBACK: '/callback',
  CUSTOM_AMOUNT: '/manage-plan/custom-amount',
  ARTICLES: '/articles',
  VERIFY: '/verify',
  MAINTENANCE_PAGE: '/maintenance'
};

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,12}$/,
  PASSWORD_SIGNUP:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]{8,64}$/,
  CONTACT_NO: /^[0-9]+$/i,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  USERNAME: /^[a-z0-9]+$/
};

export const TOKEN = 'Token';
export const REFRESH_TOKEN = 'RefreshToken';
export const USER = 'User';

export const MODULE_TYPES = {
  VIDEO_CONTINUE_WATCHING_CAROUSEL: 'VIDEO_CONTINUE_WATCHING_CAROUSEL',
  VIDEO_CAROUSEL: 'VIDEO_CAROUSEL',
  VIDEO_LIST: 'VIDEO_LIST',
  VIDEO_GRID: 'VIDEO_GRID',
  VIDEO_PLAYER: 'VIDEO_PLAYER',
  FEATURED_VIDEO: 'FEATURED_VIDEO',
  PODCAST_LIST: 'PODCAST_LIST',
  PODCAST_GRID: 'PODCAST_GRID',
  PODCAST_PLAYER: 'PODCAST_PLAYER',
  FEATURED_VIDEO_SLIDER: 'FEATURED_VIDEO_SLIDER',
  COLLECTION_CAROUSEL: 'COLLECTION_CAROUSEL',
  COLLECTION_LIST: 'COLLECTION_LIST',
  COLLECTION_GRID: 'COLLECTION_GRID',
  FEATURED_COLLECTION: 'FEATURED_COLLECTION',
  FEATURED_COLLECTION_SLIDER: 'FEATURED_COLLECTION_SLIDER',
  COLLECTION_PILL_CAROUSEL: 'COLLECTION_PILL_CAROUSEL',
  COLLECTION_DETAIL: 'COLLECTION_DETAIL',
  HTML_TEXT_BLOCK: 'HTML_TEXT_BLOCK',
  TOPIC_CAROUSEL: 'TOPIC_CAROUSEL',
  TOPIC_GRID: 'TOPIC_GRID',
  TOPIC_LIST: 'TOPIC_LIST',
  FEATURED_TOPIC: 'FEATURED_TOPIC',
  TAG_CAROUSEL: 'TAG_CAROUSEL',
  TAG_CLOUD: 'TAG_CLOUD',
  FEATURED_TAG: 'FEATURED_TAG',
  CTA_BLOCK: 'CTA_BLOCK',
  CTA_BANNER: 'CTA_BANNER',
  CTA_BANNER_WITH_TEXT: 'CTA_BANNER_WITH_TEXT',
  HERO: 'HERO',
  INFORMATION: 'INFORMATION',
  FAQ: 'FAQ',
  SPACER: 'SPACER',
  FORM_CAROUSEL: 'FORM_CAROUSEL',
  FORM_DISPLAY: 'FORM_DISPLAY',
  SUBSCRIPTION_HEADER: 'SUBSCRIPTION_HEADER',
  SUBSCRIPTION_BLOCK: 'SUBSCRIPTION_BLOCK',
  ARTICLE_LIST: 'ARTICLE_LIST',
  CTA_BUTTON_LIST: 'CTA_BUTTON_LIST',
  SUBSCRIPTION_LIST: 'SUBSCRIPTION_LIST'
};
export const PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const defaultSubtitle = 'English';
export const SHARE_URL = 'http://localhost:3000/video-player';
export const VIDEO_PLAYER_NAME = 'REACT_PLAYER';
export const TRACK_KIND = {
  SUBTITLES: 'subtitles',
  CAPTIONS: 'captions',
  CHAPTERS: 'chapters'
};

export const TRACK_MODE = {
  SHOWING: 'showing',
  DISABLED: 'disabled'
};

export const PAGE_TYPE = {
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC',
  CUSTOM: 'CUSTOM',
  VIDEO: 'VIDEO',
  STATIC: 'STATIC',
  PODCAST: 'PODCAST',
  FORM: 'FORM'
};

export const PAGE_SLUG = {
  COLLECTION: ROUTES.COLLECTIONS,
  TAG: ROUTES.TAGS,
  TOPIC: ROUTES.TOPICS,
  CUSTOM: '',
  VIDEO: ROUTES.VIDEOS,
  STATIC: '',
  PODCAST: ROUTES.PODCASTS,
  FORM: ROUTES.FORMS
};

export const ARTICLE_ACTION_TYPE = [
  { name: 'Load More', value: 'LOAD_MORE' },
  { name: 'View All', value: 'VIEW_ALL' }
];

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  PAYMENT_DUE: 'PAYMENT_DUE',
  CANCELLED_AT_PERIOD_END: 'CANCELLED_AT_PERIOD_END'
};

export const DATE_FORMATS = {
  VIDEO_MODULE: 'MM/DD/YYYY',
  HERO_MODULE: 'MMMM Do @ h:mm A',
  CTA_EVENT_BLOCK_MODULE: 'MMMM Do @ h:mm A',
  PODCAST_MODULE: 'MM/DD/YYYY',
  BILLING_MODULE: 'MMMM D, YYYY'
};

export const VIDEOS = [
  {
    id: 1,
    title: 'Random m3u8 one',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8'
  },
  {
    id: 2,
    title: 'Random m3u8 two',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'https://res.cloudinary.com/dannykeane/video/upload/sp_full_hd/q_80:qmax_90,ac_none/v1/dk-memoji-dark.m3u8'
  },
  {
    id: 3,
    title: 'Random mp4 1',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
  },
  {
    id: 4,
    title: 'Random mp4 2',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'
  },
  {
    id: 5,
    title: 'Admin m3u8 1',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'https://video3cdn.cappital.co/streaming-playlists/hls/19189e03-b90f-4d0e-991d-4c8aee34a5e9/5f44e513-d398-4f93-8e8a-074d788fc0fc-master.m3u8'
  },
  {
    id: 6,
    title: 'Admin m3u8 2',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'https://video3cdn.cappital.co/streaming-playlists/hls/96ab37a8-ca76-4249-a601-7c0679d127c1/eff24370-e43f-4146-bd6c-592baca0fb82-master.m3u8'
  },
  {
    id: 7,
    title: 'Tik Tok Watch Temporary',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'https://video3cdn.cappital.co/streaming-playlists/hls/65fb42ad-7dd4-410f-8a28-63fc8f6111e7/2067a88e-baa9-4dfe-800e-3bb3ef8ae688-master.m3u8'
  },
  {
    id: 8,
    title: 'For Bigger Joyrides',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src: 'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src: 'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src: 'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url: 'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  }
];

export const ASSET_CATEGORY = {
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT'
};

export const ALIGNMENTS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};

export const THEMES = {
  NEUTRAL_300: 'NEUTRAL_300',
  NEUTRAL_400: 'NEUTRAL_400',
  NEUTRAL_900: 'NEUTRAL_900',
  NEUTRAL_1000: 'NEUTRAL_1000',
  BLUE: 'BLUE',
  BLUE_SHADE: 'BLUE_SHADE',
  BLUE_TINT: 'BLUE_TINT',
  MOSS: 'MOSS',
  MOSS_TINT: 'MOSS_TINT',
  YELLOW: 'YELLOW'
};

export const THEME_PICKER_COLORS = {
  [THEMES.NEUTRAL_300]: {
    backgroundColor: 'bg-n-300',
    textLight: false
  },
  [THEMES.NEUTRAL_400]: {
    backgroundColor: 'bg-n-400',
    textLight: false
  },
  [THEMES.NEUTRAL_900]: {
    backgroundColor: 'bg-n-1000',
    textLight: true
  },
  [THEMES.NEUTRAL_1000]: {
    backgroundColor: 'bg-n-1000',
    textLight: true
  },
  [THEMES.BLUE]: {
    backgroundColor: 'bg-blue-2',
    textLight: false
  },
  [THEMES.BLUE_SHADE]: {
    backgroundColor: 'bg-blue-3',
    textLight: true
  },
  [THEMES.BLUE_TINT]: {
    backgroundColor: 'bg-blue',
    textLight: false
  },
  [THEMES.MOSS]: {
    backgroundColor: 'bg-moss-2',
    textLight: false
  },
  [THEMES.MOSS_TINT]: {
    backgroundColor: 'bg-moss',
    textLight: false
  },
  [THEMES.YELLOW]: {
    backgroundColor: 'bg-yellow-2',
    textLight: false
  }
};

export const ACTION_BUTTON_TYPES = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  EXTERNAL_IN_APP: 'EXTERNAL_IN_APP'
};

export const STATIC_PAGES = {
  HOME: '/'
};

export const RATIOS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

export const MATOMO_CATEGORY = {
  VIDEO: 'VIDEO',
  PREVIEW_VIDEO: 'PREVIEW_VIDEO',
  AUTH: 'AUTH',
  CONVERSATION: 'CONVERSATION',
  TOPICS: 'TOPICS',
  COLLECTION: 'COLLECTION',
  TAGS: 'TAGS',
  CTA: 'CTA',
  HERO: 'HERO',
  INFORMATION: 'INFORMATION',
  FORM: 'FORM',
  PODCAST_VIDEO: 'PODCAST_VIDEO',
  PODCAST_AUDIO: 'PODCAST_AUDIO',
  PREVIEW_PODCAST_VIDEO: 'PREVIEW_PODCAST_VIDEO',
  PREVIEW_PODCAST_AUDIO: 'PREVIEW_PODCAST_AUDIO',
  VIEW_DETAIL: 'VIEW_DETAIL',
  SUBSCRIPTION: 'SUBSCRIPTION'
};

export const MATOMO_ACTION = {
  VIDEO_SHARED: 'VIDEO_SHARED',
  PODCAST_SHARED: 'PODCAST_SHARED',
  MIGRATED_USER: 'MIGRATED_USER',
  SIGN_IN: 'SIGN_IN',
  LOGOUT: 'LOGOUT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  CONVERSATION_CREATED: 'CONVERSATION_CREATED',
  CONVERSATION_UPDATED: 'CONVERSATION_UPDATED',
  VIDEO_ADDED_TO_CONVERSATION: 'VIDEO_ADDED_TO_CONVERSATION',
  CONVERSATION_RSVP_SENT: 'CONVERSATION_RSVP_SENT',
  CONVERSATION_RSVP_ACCEPTED: 'CONVERSATION_RSVP_ACCEPTED',
  VIDEO_PLAY: 'VIDEO_PLAY',
  VIDEO_PAUSE: 'VIDEO_PAUSE',
  VIDEO_START: 'VIDEO_START',
  VIDEO_END: 'VIDEO_END',
  VIDEO_STOPPED: 'VIDEO_STOPPED',
  AUDIO_PLAY: 'AUDIO_PLAY',
  AUDIO_PAUSE: 'AUDIO_PAUSE',
  AUDIO_START: 'AUDIO_START',
  AUDIO_END: 'AUDIO_END',
  AUDIO_STOPPED: 'AUDIO_STOPPED',
  VIDEO_WATCHED_TILL_END: 'VIDEO_WATCHED_TILL_END',
  AVERAGE_WATCH_TIME: 'AVERAGE_WATCH_TIME',
  TOPICS_CARD_CLICKED: 'TOPICS_CARD_CLICKED',
  COLLECTION_CARD_CLICKED: 'COLLECTION_CARD_CLICKED',
  VIDEO_CARD_CLICKED: 'VIDEO_CARD_CLICKED',
  TAGS_CARD_CLICKED: 'TAGS_CARD_CLICKED',
  CTA_BTN_CLICKED: 'CTA_BTN_CLICKED',
  HERO_BTN_CLICKED: 'HERO_BTN_CLICKED',
  INFORMATION_BTN_CLICKED: 'INFORMATION_BTN_CLICKED',
  CONTACT_FORM_SUBMITTED: 'CONTACT_FORM_SUBMITTED',
  FEATURED_COLLECTION_BTN_CLICKED: 'FEATURED_COLLECTION_BTN_CLICKED',
  FEATURED_VIDEO_BTN_CLICKED: 'FEATURED_VIDEO_BTN_CLICKED',
  FORM_SUBMITTED: 'FORM_SUBMITTED',
  ASK_QUESTION_BTN_CLICKED: 'ASK_QUESTION_BTN_CLICKED',
  SUBSCRIPTION_SUCCESSFUL: 'SUBSCRIPTION_SUCCESSFUL',
  SUBSCRIPTION_FAIL: 'SUBSCRIPTION_FAIL',
  FORM_SIGNED: 'FORM_SIGNED',
  VIEW_VIDEO: 'VIEW_VIDEO',
  VIEW_FORM: 'VIEW_FORM',
  VIEW_ARTICLE: 'VIEW_ARTICLE',
  VIEW_PODCAST: 'VIEW_PODCAST',
  VIEW_TOPIC: 'VIEW_TOPIC',
  VIEW_COLLECTION: 'VIEW_COLLECTION',
  CTA_BLOCK_PRIMARY_BTN_CLICKED: 'CTA_BLOCK_PRIMARY_BTN_CLICKED',
  CTA_BLOCK_SECONDARY_BTN_CLICKED: 'CTA_BLOCK_SECONDARY_BTN_CLICKED',
  CTA_HERO_PRIMARY_BTN_CLICKED: 'CTA_HERO_PRIMARY_BTN_CLICKED',
  CTA_HERO_SECONDARY_BTN_CLICKED: 'CTA_HERO_SECONDARY_BTN_CLICKED',
  CTA_BANNER_PRIMARY_BTN_CLICKED: 'CTA_BANNER_PRIMARY_BTN_CLICKED',
  CTA_INFORMATION_PRIMARY_BTN_CLICKED: 'CTA_INFORMATION_PRIMARY_BTN_CLICKED',
  CTA_INFORMATION_SECONDARY_BTN_CLICKED:
    'CTA_INFORMATION_SECONDARY_BTN_CLICKED',
  FEATURE_TOPICS_CARD_CLICKED: 'FEATURE_TOPICS_CARD_CLICKED',
  COLLECTION_PILL_CARD_CLICKED: 'COLLECTION_PILL_CARD_CLICKED',
  SUBSCRIPTION_PURCHASE: 'SUBSCRIPTION_PURCHASE',
  SUBSCRIPTION_FAILED: 'SUBSCRIPTION_FAILED',
  SUBSCRIPTION_CANCEL: 'SUBSCRIPTION_CANCEL',
  SUBSCRIPTION_UPDATE: 'SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_INITIALIZED: 'SUBSCRIPTION_INITIALIZED',
  SUBSCRIPTION_BILLING_ADDRESS_ADDED: 'SUBSCRIPTION_BILLING_ADDRESS_ADDED',
  SUBSCRIPTION_SHIPPING_ADDRESS_ADDED: 'SUBSCRIPTION_SHIPPING_ADDRESS_ADDED',
  VIEW_TAG: 'VIEW_TAG'
};

export const TRACK_VALUE = {
  SUCCESS: 1,
  FAIL: 0
};

export const CONVERSATION_DATE_FORMAT = 'MMM D';

export const SESSION_STATUS = {
  AUTHENTICATED: 'authenticated',
  LOADING: 'loading',
  UNAUTHENTICATED: 'unauthenticated'
};

export const TEXT_ALIGNMENTS_CLASS = {
  LEFT: 'text-left',
  CENTER: 'text-center',
  RIGHT: 'text-right',
  JUSTIFY: 'text-justify'
};
export const BUTTON_ALIGNMENT_CLASS = {
  LEFT: 'justify-start',
  CENTER: 'justify-center',
  RIGHT: 'justify-end',
  JUSTIFY: 'justify-start'
};

export const VIDEO_TYPES = {
  WATCH_LATER: 'watch_later',
  CONTINUE_WATCHING: 'continue_watching'
};

export const RESOURCES_LABELS = {
  QUESTIONS: 'Questions',
  LINKS: 'Links',
  SCRIPTURE: 'Scripture'
};

export const HISTORY_TYPES = {
  VIDEO: 'VIDEO'
};

export const VIDEO_FROM_TYPES = {
  PLAYLIST: 'playlist'
};

export const COLOR_KEYS = {
  neutral0: {
    variable: '--tq-neutral-0',
    color: '#ffffff',
    backgroundClass: 'bg-n-0'
  },
  neutral100: {
    variable: '--tq-neutral-100',
    color: '#fff6e6',
    backgroundClass: 'bg-n-100'
  },
  neutral200: {
    variable: '--tq-neutral-200',
    color: '#fef3e3',
    backgroundClass: 'bg-n-200'
  },
  neutral300: {
    variable: '--tq-neutral-300',
    color: '#fcf1e1',
    backgroundClass: 'bg-n-300'
  },
  neutral400: {
    variable: '--tq-neutral-400',
    color: '#f9eede',
    backgroundClass: 'bg-n-400'
  },
  neutral500: {
    variable: '--tq-neutral-500',
    color: '#7f848f',
    backgroundClass: 'bg-n-500'
  },
  neutral600: {
    variable: '--tq-neutral-600',
    color: '#666666',
    backgroundClass: 'bg-n-600'
  },
  neutral700: {
    variable: '--tq-neutral-700',
    color: '#2e2e2e',
    backgroundClass: 'bg-n-700'
  },
  neutral800: {
    variable: '--tq-neutral-800',
    color: '#ff712a',
    backgroundClass: 'bg-n-800'
  },
  neutral900: {
    variable: '--tq-neutral-900',
    color: '#fd5704',
    backgroundClass: 'bg-n-900'
  },
  neutral1000: {
    variable: '--tq-neutral-1000',
    color: '#000000',
    backgroundClass: 'bg-n-1000'
  },
  ivory: {
    variable: '--tq-ivory',
    color: '#111111',
    backgroundClass: 'bg-n-0'
  },
  white90: {
    variable: '--tq-white-90',
    color: '#ffffff',
    backgroundClass: 'bg-white-90'
  },
  white80: {
    variable: '--tq-white-80',
    color: '#ebebeb',
    backgroundClass: 'bg-white-80'
  },
  white70: {
    variable: '--tq-white-70',
    color: '#e9e9e9',
    backgroundClass: 'bg-white-70'
  },
  black90: {
    variable: '--tq-black-90',
    color: '#ffffff',
    backgroundClass: 'bg-black-90'
  },
  black80: {
    variable: '--tq-black-80',
    color: '#ffffffc7',
    backgroundClass: 'bg-black-80'
  },
  black70: {
    variable: '--tq-black-70',
    color: '#ffffffc7',
    backgroundClass: 'bg-black-70'
  },
  red: {
    variable: '--tq-red',
    color: '#ff7b4c',
    backgroundClass: 'bg-red'
  },
  red2: {
    variable: '--tq-red-2',
    color: '#fe4819',
    backgroundClass: 'bg-red-2'
  },
  red3: {
    variable: '--tq-red-3',
    color: '#b02501',
    backgroundClass: 'bg-red-3'
  },
  yellow: {
    variable: '--tq-yellow',
    color: '#ffe546',
    backgroundClass: 'bg-yellow'
  },
  yellow2: {
    variable: '--tq-yellow-2',
    color: '#f4b213',
    backgroundClass: 'bg-yellow-2'
  },
  yellow3: {
    variable: '--tq-yellow-3',
    color: '#9a6f07',
    backgroundClass: 'bg-yellow-3'
  },
  blue: {
    variable: '--tq-blue',
    color: '#e1fdff',
    backgroundClass: 'bg-blue'
  },
  blue2: {
    variable: '--tq-blue-2',
    color: '#aecacd',
    backgroundClass: 'bg-blue-2'
  },
  blue3: {
    variable: '--tq-blue-3',
    color: '#6fa1a6',
    backgroundClass: 'bg-blue-3'
  },
  moss: {
    variable: '--tq-moss',
    color: '#d7c895',
    backgroundClass: 'bg-moss'
  },
  moss2: {
    variable: '--tq-moss-2',
    color: '#a49562',
    backgroundClass: 'bg-moss-2'
  },
  moss3: {
    variable: '--tq-moss-3',
    color: '#655c3b',
    backgroundClass: 'bg-moss-3'
  },
  borderDark: {
    variable: '--tq-border-dark',
    color: '#484646',
    backgroundClass: 'bg-border-dark'
  },
  borderLight: {
    variable: '--tq-border-light',
    color: '#e9e9e9',
    backgroundClass: 'bg-border-light'
  },
  footerBg: {
    variable: '--tq-footer-bg',
    color: '#000000',
    backgroundClass: 'bg-footer'
  }
};

export const ASSET_SERVICES = {
  MUX: 'MUX',
  JW_PLAYER: 'JW_PLAYER',
  MANUAL: 'MANUAL',
  PEERTUBE: 'PEERTUBE',
  BUNNY_STREAM: 'BUNNY_STREAM',
  BRIGHTCOVE: 'BRIGHTCOVE'
};

export const PODCAST_TYPES = {
  WATCH_LATER: 'watch_later',
  CONTINUE_WATCHING: 'continue_watching'
};

export const LOGO_SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

export const LOGO_POSITIONS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};

export const AUTH_STATUS = {
  AUTHENTICATED: 'authenticated',
  LOADING: 'loading',
  UNAUTHENTICATED: 'unauthenticated'
};

export const SUBSCRIPTION_PLAN_KEYS = {
  FREE: 'FREE',
  GUEST: 'GUEST',
  HEAVYWEIGHT_MONTHLY: 'HEAVYWEIGHT_MONTHLY',
  HEAVYWEIGHT_ANNUALLY: 'HEAVYWEIGHT_ANNUALLY'
};

export const CACHE_REVALIDATION_TIME = 60 * 5; // 5 minutes

export const staticUiLabels = {
  RESET_PASSWORD_TITLE: 'Reset Password',
  FORGOT_PASSWORD_TITLE: 'Forgot Password',
  NEWS_LETTER_DESCRIPTION: `You will receive email marketing messages from Breitbart News Network. You may unsubscribe at any time.`,
  NEWS_LETTER_TITLE: 'Get our newsletters via email',
  SIGNUP_FORM_TITLE: 'Create an account',
  SIGNUP_FORM_DESCRIPTION: '',
  SETTING_PAGE_TITLE: 'Profile & Password',
  CHANGES_PASSWORD_TITLE: 'Change Password',
  LOGIN_FORM_TITLE: 'Welcome Back!',
  LOGIN_FORM_DESCRIPTION: 'Please sign in to continue.',
  DOWNLOAD_APP: 'Download App',
  SUBSCRIPTION_HISTORY_TITLE: 'History',
  SUBSCRIPTION_PAYMENT_METHOD: 'PAYMENT METHOD',
  SUBSCRIPTION_BILLING_TITLE: 'BILLING',
  PAGE_TITLE_500: 'Oops! Something Went Wrong',
  PAGE_DESCRIPTION_500: `We&apos;re sorry, but it seems there&apos;s been an internal server error. Please try again later. Thank you for your patience!`,
  PAGE_TITLE_400: 'Oops page not found',
  PAGE_DESCRIPTION_400: `We couldn&apos;t find the page you&apos;re looking for. It&apos;s
        possible that the page has been moved, deleted, or never existed in
        the first place. Please double-check the URL or go back to our
        homepage to explore more. If you believe there&apos;s a problem with
        our website, feel free to contact us. We apologize for any
        inconvenience.`,
  PAGE_TITLE_403: 'Restricted Access',
  PAGE_DESCRIPTION_403: ``,
  SUBSCRIPTION_DISCOUNT: `Save 16%`,
  PAYMENT_DETAILS_SUCCESS_PAGE_TITLE: `Thank you for joining the Breitbart Fight Club.`,
  PAYMENT_DETAILS_SUCCESS_PAGE_THANK_YOU_TITLE: '',
  PAYMENT_DETAILS_SUCCESS_PAGE_THANK_YOU_DESCRIPTION: '',
  SUBSCRIPTION_SUCCESSFUL_MESSAGE: `You are now a Breitbart subscriber. Enjoy your new unrestricted experience!`,
  PREMIUM_BLOCK_BUTTON: 'SUBSCRIBE NOW',
  PREMIUM_BLOCK_DESC: `Join the Insider Club and Unlock the Full Potential`,
  PREMIUM_BLOCK_TITLE: `You're using a limited version.`,
  PREMIUM_BLOCK_IMAGE: `${process.env.NEXT_APP_STATIC_DATA_URL}/subPlanImg.svg`,
  VIDEO_SIGNUP_POPUP_BUTTON_LABEL: '',
  VIDEO_SIGNUP_POPUP_DESCRIPTION: '',
  VIDEO_SIGNUP_POPUP_TITLE: '',
  UPDATE_PROFILE_TITLE: 'Update Profile',
  SUBSCRIPTION_UPDATE_PAYMENT_METHOD: 'Update Payment Method',
  SUBSCRIPTION_BILLING_ADDRESS: 'BILLING ADDRESS',
  SUBSCRIPTION_SHIPPING_ADDRESS: 'SHIPPING ADDRESS',
  SUBSCRIPTION_PAYMENT_FAILED_TITLE: 'Your payment has failed',
  SUBSCRIPTION_PAYMENT_FAILED_DESCRIPTION:
    'you can retry the payment below to continue this',
  WITHOUT_LOGIN_PREMIUM_BLOCK_DESC:
    'Join the Breitbart Fight Club for full access',
  WITHOUT_LOGIN_PREMIUM_BLOCK_BUTTON: 'SUBSCRIBE NOW',
  WITHOUT_LOGIN_PREMIUM_BLOCK_TITLE: '',
  WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_TEXT: `Already have an account?`,
  WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_IMAGE: `${process.env.NEXT_APP_STATIC_DATA_URL}/subPlanImage.svg`,
  VERIFY_YOUR_EMAIL_SUCCESS_MESSAGE:
    'Your email has been successfully verified! You can now continue to the Breitbart homepage.',
  EMAIL_VERIFICATION_FAILED_MESSAGE:
    'It seems your account is already verified or the link is invalid/expired.'
};

export const PAGE_GROUPS = {
  SUBSCRIPTION: 'SUBSCRIPTION'
};

// cookie expiry time is set to 34 days = 2937600 seconds
export const COOKIE_EXPIRY = 2937600;

export const ERROR_MESSAGES = {
  PASSWORD_STRENGTH:
    'at least 8 characters, an uppercase letter, a lowercase letter, a number and a special character'
};

export const ONE_TRUST_COOKIE_CONSENT_GROUPS = {
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003'
};
