function Hamburgur() {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.155 5.608H24.488V8.118H3.155V5.608Z" fill="white" />
      <path d="M3.155 11.882H24.488V14.392H3.155V11.882Z" fill="white" />
      <path d="M3.155 18.157H24.488V20.667H3.155V18.157Z" fill="white" />
    </svg>
  );
}
export default Hamburgur;
