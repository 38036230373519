import { Avatar, Popover } from 'antd';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import {
  BREAKPOINTS,
  LOGO_POSITIONS,
  LOGO_SIZES,
  ROUTES
} from '../common/constants';
import useSidebarMenus from '../common/useSidebarMenus';
import { formatPhoneNumber, getImageUrl, getShortName } from '../common/utils';
import { useApp } from '../providers/AppContext';
import CustomAnchor from './CustomAnchor';
import Hamburgur from './imageComponent/Hamburgur';
import SearchIcon from './imageComponent/SearchIcon';
import UserIcon from './imageComponent/UserIcon';
import Menubar from './metaComponent/Menubar';
import AccountProfile from './metaComponent/account/AccountProfile';

const LOGO_POSITION_CLASSES = {
  [LOGO_POSITIONS.CENTER]: 'mx-auto',
  [LOGO_POSITIONS.RIGHT]: 'ml-auto'
};
const LOGO_SIZE_CLASSES = {
  [LOGO_SIZES.SMALL]: 'small',
  [LOGO_SIZES.MEDIUM]: 'medium',
  [LOGO_SIZES.LARGE]: 'large'
};

function Header({
  menuItems,
  headerLogo,
  appName,
  headerLogoPosition,
  headerLogoSize,
  showMenu
}) {
  const [open, setOpen] = useState(false);
  const [isDesktop, setDesktop] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useSession();
  const {
    state: { config, currentUser, loading, isAuthenticated }
  } = useApp();

  const userBadgeImage = config?.BADGES?.value?.filter(
    (item) => item?.id === currentUser?.badgeId
  );

  const menus = useSidebarMenus();

  useEffect(() => {
    setDesktop(window.innerWidth > BREAKPOINTS.mobile);
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window.innerWidth > BREAKPOINTS.mobile);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <header
      className={`header-${LOGO_SIZE_CLASSES[headerLogoSize?.value] ?? ''}`}
    >
      <div className="container">
        <div className="header-wrapper">
          <div className="header-left">
            {showMenu?.value === 'YES' && (
              <Popover
                overlayClassName="header-menu-popover"
                placement="bottomLeft"
                getPopupContainer={(trigger) => trigger?.parentNode}
                content={
                  <Menubar
                    className={`${
                      LOGO_POSITION_CLASSES[headerLogoPosition?.value] ?? ''
                    }`}
                    aria-hidden="true"
                    menuItems={menuItems}
                    setIsOpen={setIsOpen}
                    onClose={() => setOpenMenu(false)}
                  />
                }
                arrow={false}
                open={openMenu}
                onOpenChange={(value) => setOpenMenu(value)}
                trigger="click"
              >
                <div
                  className="d-hide pointer menu-icon"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Menu
                  <Hamburgur />
                </div>
              </Popover>
            )}

            <span
              onClick={() => setIsOpen(false)}
              className={`menu-overlay ${isOpen ? `active` : ``}`}
            />
            <div
              className={`menu-bar ${isOpen ? `menu-open` : ``} ${
                LOGO_POSITION_CLASSES[headerLogoPosition?.value] ?? ''
              }`}
            >
              <div className="responsive-menu-header">
                <a
                  className={`logo-wrapper ${
                    LOGO_SIZE_CLASSES[headerLogoSize?.value] ?? ''
                  } ${LOGO_POSITION_CLASSES[headerLogoPosition?.value] ?? ''}`}
                  aria-label="header logo"
                  href={process.env.NEXT_PUBLIC_WP_REDIRECT_URL}
                  onClick={() => setIsOpen(false)}
                >
                  <Image
                    className="logo"
                    src={headerLogo?.value}
                    alt={appName}
                    height={200}
                    width={200}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="header-right">
            <div className="auth-link">
              <ul className="d-flex gap-8">
                <li>
                  <CustomAnchor
                    className="search-link link-auth"
                    href={ROUTES.SEARCH}
                  >
                    <SearchIcon />
                  </CustomAnchor>
                </li>
                {!loading && !isAuthenticated && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!data?.accessToken && (
                      <li>
                        <CustomAnchor className="link-auth" href={ROUTES.LOGIN}>
                          LOGIN
                        </CustomAnchor>
                      </li>
                    )}
                  </>
                )}
                {!loading && isAuthenticated && isDesktop && (
                  <li>
                    <Link href={`${ROUTES.ACCOUNT_SETTING}`}>
                      <Avatar
                        {...(currentUser?.profileImage && {
                          src: getImageUrl(currentUser?.profileImage, {
                            height: 100,
                            width: 100
                          })
                        })}
                        size="small"
                        className="bg-blue-2 label-s"
                        alt={getShortName(
                          currentUser?.firstName,
                          currentUser?.lastName
                        )}
                      >
                        <UserIcon />
                      </Avatar>
                    </Link>
                  </li>
                )}
                {!loading && isAuthenticated && !isDesktop && (
                  <li>
                    <Popover
                      overlayClassName="header-profile-popover"
                      placement="leftTop"
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      content={
                        <AccountProfile
                          shortName={getShortName(
                            currentUser?.firstName,
                            currentUser?.lastName
                          )}
                          {...(currentUser?.contactNumber && {
                            number: `${
                              currentUser?.countryCode
                            } ${formatPhoneNumber(currentUser?.contactNumber)}`
                          })}
                          uid={currentUser?.uid}
                          data={menus}
                          profileImage={getImageUrl(currentUser?.profileImage, {
                            height: 100,
                            width: 100
                          })}
                          {...(currentUser?.badgeId && {
                            badgeImage: userBadgeImage?.[0].image.url
                          })}
                          onClose={() => setOpen(false)}
                        />
                      }
                      arrow={false}
                      open={open}
                      onOpenChange={(value) => setOpen(value)}
                      trigger="click"
                    >
                      <Link href={`${ROUTES.ACCOUNT_SETTING}`}>
                        <Avatar
                          {...(currentUser?.profileImage && {
                            src: getImageUrl(currentUser?.profileImage, {
                              height: 100,
                              width: 100
                            })
                          })}
                          size="small"
                          className="bg-blue-2 label-s"
                          alt={getShortName(
                            currentUser?.firstName,
                            currentUser?.lastName
                          )}
                        >
                          <UserIcon />
                        </Avatar>
                      </Link>
                    </Popover>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
