import React from 'react';

function RouteProgress() {
  return (
    <div className="ipl-progress-indicator">
      <div className="ipl-progress-indicator-head">
        <div className="first-indicator" />
        <div className="second-indicator" />
      </div>
    </div>
  );
}

export default RouteProgress;
