import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AUTH_STATUS, ROUTES } from '../common/constants';

const AppContext = createContext();

function AppContextProvider({ children, config, pageGroups }) {
  const { data, status, update: updateSession } = useSession();
  const { push, asPath } = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const placeholderImage = useMemo(
    () => config?.DEFAULT_DISPLAY_IMAGE?.value || config?.APP_IMAGE?.value,
    [config]
  );

  const loading = status === AUTH_STATUS.LOADING || isLoading;
  const isAuthenticated = status === AUTH_STATUS.AUTHENTICATED;
  const isUnAuthenticated = status === AUTH_STATUS.UNAUTHENTICATED;

  useEffect(() => {
    if (isAuthenticated && data && data?.errorCode) {
      (async () => {
        await signOut({
          redirect: false
        });

        push({
          pathname: ROUTES.LOGIN
        });
      })();
    }
  }, [data, isAuthenticated, push, asPath]);

  const values = useMemo(
    () => ({
      state: {
        config,
        placeholderImage,
        loading,
        currentUser: data?.user,
        accessToken: data?.accessToken,
        isAuthenticated,
        isUnAuthenticated,
        pageGroups
      },
      setIsLoading,
      updateSession
    }),
    [
      config,
      placeholderImage,
      data,
      loading,
      isAuthenticated,
      isUnAuthenticated,
      pageGroups,
      updateSession
    ]
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

const useApp = () => {
  const ctx = useContext(AppContext);
  if (!ctx) throw new Error('useApp must be used within AppContext');
  return ctx;
};

export { AppContext, AppContextProvider, useApp };
