import {
  createInstance,
  MatomoProvider,
  useMatomo
} from '@datapunt/matomo-tracker-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ONE_TRUST_COOKIE_CONSENT_GROUPS } from '../common/constants';

const MATOMO_URL = process.env.NEXT_APP_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_APP_MATOMO_SITE_ID;

let instance = null; // Matomo instance should be null if no consent

function MatomoTrackPage() {
  const { trackPageView } = useMatomo();
  const router = useRouter();

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.pathname]);

  return false;
}

export default function Matomo({ children }) {
  const [enabled, setEnabled] = useState(false);
  const [consentChanged, setConsentChanged] = useState(false);
  const router = useRouter();

  const [isOneTrustLoaded, setIsOneTrustLoaded] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      if (window?.OneTrust) {
        setIsOneTrustLoaded(true);
        clearInterval(id);
      }
    }, 200);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    const handleConsentChange = () => {
      // This will be triggered when user accepts/rejects cookies
      setConsentChanged((prev) => !prev); // Toggle state to rerender the component
    };

    // Add event listener for OneTrust consent changes
    if (isOneTrustLoaded)
      window?.OneTrust?.OnConsentChanged(handleConsentChange);
  }, [isOneTrustLoaded]);

  useEffect(() => {
    if (isOneTrustLoaded && window?.OnetrustActiveGroups) {
      const groups = window.OnetrustActiveGroups.split(',');
      if (groups.includes(ONE_TRUST_COOKIE_CONSENT_GROUPS.PERFORMANCE)) {
        setEnabled(true);

        // Initialize Matomo only when cookies consent is granted
        if (!instance) {
          instance = createInstance({
            urlBase: MATOMO_URL,
            siteId: MATOMO_SITE_ID
          });
        }
      } else {
        setEnabled(false);
        instance = null; // Clear instance if consent is not given
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.events, consentChanged, isOneTrustLoaded]);

  return (
    <MatomoProvider value={enabled ? instance : null}>
      {enabled && <MatomoTrackPage />}
      {children}
    </MatomoProvider>
  );
}
