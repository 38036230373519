import Link from 'next/link';
import { PAGE_SLUG } from '../common/constants';

function CustomLink({ type, name, url, target, internalPageType, icon }) {
  if (type === 'INTERNAL')
    return (
      <Link
        prefetch={false}
        target={target}
        href={`${PAGE_SLUG[internalPageType] ?? ''}${
          url === '/' ? '' : '/'
        }${url}`}
      >
        {icon} {name}
      </Link>
    );
  return (
    <a target={target} href={url} rel="noopener noreferrer">
      {icon} {name}
    </a>
  );
}

export default CustomLink;
