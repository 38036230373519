function CaretRight() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3839 8.88388L6.73741 13.5303C6.54215 13.7256 6.22556 13.7256 6.0303 13.5303L4.96964 12.4697C4.77438 12.2744 4.77438 11.9578 4.96964 11.7626L8.73221 8L4.96964 4.23743C4.77438 4.04217 4.77438 3.72559 4.96964 3.53033L6.0303 2.46967C6.22556 2.2744 6.54215 2.2744 6.73741 2.46967L11.3839 7.11611C11.872 7.60427 11.872 8.39572 11.3839 8.88388Z"
        fill="#FF3838"
      />
    </svg>
  );
}
export default CaretRight;
