function SearchIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7748 16.896C13.6856 17.5948 12.3901 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.3901 17.5948 13.6856 16.8961 14.7747L19.9077 17.7864C20.2006 18.0793 20.2006 18.5541 19.9077 18.847L18.8471 19.9077C18.5542 20.2006 18.0793 20.2006 17.7864 19.9077L14.7748 16.896ZM16.5 11C16.5 14.0376 14.0376 16.5 11 16.5C7.96243 16.5 5.5 14.0376 5.5 11C5.5 7.96243 7.96243 5.5 11 5.5C14.0376 5.5 16.5 7.96243 16.5 11Z"
        fill="white"
      />
    </svg>
  );
}
export default SearchIcon;
